<template>
  <p class="clearfix mb-0 text-center">
    <span class="d-block d-md-inline-block mt-25">
      Copyright  © {{ new Date().getFullYear() }} BestSeat360 Ltd Patents Pending
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
