<template>
  <div class="navbar-container d-flex content align-items-center py-2">
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <a class="navbar-brand" href="/">
        <img :src="require('@/assets/logo.png')" width="160">
      </a>
    </div>

    <div class="nav align-items-center ml-auto">
      <img :src="topRightImage" width="160">
    </div>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  data() {
    return {
      topRightImage: null,
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    const activeBrand = JSON.parse(localStorage.getItem('activeBrand'))
    setTimeout(() => {
      if (activeBrand && activeBrand.topRightImage) {
        this.topRightImage = activeBrand.topRightImage
      }
    }, 800)
  }
}
</script>
